.icon-bx-wraper{
	// Style 1
	&.style-1{
		padding: 30px;
		@include transitionMedium;
		margin-top: 40px;
		margin-bottom: 15px;
		&:after{
			content: "";
			height: 100%;
			width: 100%;
			background-color: var(--rgba-primary-1);
			@include transitionMedium;
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			transform: skewY(-5deg);
		}
	
		.icon-bx {
			height: 120px;
			width: 120px;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px -10px 50px 0px var(--rgba-primary-2);
			margin-top: -70px;
		}
		.icon-content {
			overflow: visible;
		}
		
		&:hover,
		&.active{
			color: var(--theme-text-color);
			
			.dz-title,
			.dz-title a{
				color: var(--theme-text-color);
			}
			.icon-cell {
				animation: move-lr 1.2s linear;
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				background-color: var(--primary);
				box-shadow: 0px 10px 30px 0px rgba(255, 127, 57, 0.2);
			}
		}
		@include respond('tab-port'){
			.icon-bx {
				height: 105px;
				width: 105px;
				margin-top: -60px;
				img {
					width: 50px;
				}
			}
		}
		@include respond('phone'){
			padding: 25px;
			.icon-bx {
				height: 95px;
				width: 95px;
				margin-top: -50px;
				img {
					width: 45px;
				}
			}
		}

	}
	
	// Style 2
	&.style-2{
		padding: 15px 15px 15px 25px;
		position: relative;
		background: white;
		transform: skewX(-12deg);
	    display: flex;
	    align-items: center;
	    box-shadow: 0px 10px 50px 0px rgba(0, 35, 55, 0.1);
		
		&:after {
			content: '';
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 7px;
		    height: 100%;
		    background: var(--primary);
		    opacity: 0.3;
			@include transitionMedium;
		}
		.icon-bx{
		    margin-right: 15px;
			transform: skewX(12deg);
			height: 35px;
			width: 35px;
			min-width: 35px;
		}
		.icon-content{
			transform: skewX(12deg);
			.dz-title{
				color: #000;
				font-weight: 500;
				font-size: 18px;
				margin-bottom: 0;
			}
		}
		&:hover {
			&:after {
		    	opacity: 1;
			}
		}
		@include respond ('phone'){
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	// Style 3
	&.style-3 {
		padding: 30px;
		background-color: var(--secondary);
		@include transitionMedium;
		color:#fff;
		z-index: 1;
		position: relative;
		overflow: hidden;
		
		.icon-bx{
			display: flex;
		}
		.icon-cell {
			display: inline-block;
			i{
				font-size: 60px;
				line-height: 1;
				display: flex;
				@include transitionMedium;
			}
			img{
				@include transitionMedium;
			}
		}
		.icon-content {
			overflow: visible;
		}
		&:after{
			content:"";
			position: absolute;
			display: inline-block;
			background-color: var(--primary);
			width: 0%;
			height: 100%;
			top: 0;
			z-index: -1;
			right: 0%;
		    transform-origin: right center;
			@include transitionMedium;
		}
		.dz-title,
		.dz-title a{
			color:#fff;
			font-size: 28px;
			font-weight: 400;
			@include respond('tab-port'){
				font-size: 24px;
			}
		}
		p {
			font-size: 15px;
			color: #E0E0E0;
			@include transitionMedium;
		}
		.read-more {
			color: var(--primary);
			letter-spacing: 2px;
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			font-family: var(--font-family-title);
			@include transitionMedium;
		}
		&:hover,
		&.active{
			color: var(--theme-text-color);
			
			p,
			.dz-title,
			.dz-title a,
			.read-more{
				color: var(--theme-text-color);
			}
			.icon-cell {
				display: inline-block;
				animation: move-lr 1.2s linear;
				i{
					color: var(--theme-text-color)
				}
				img{
					filter: brightness(0);
				}
			}
			.btn {
				background-color:#fff;
				color: #000;
			}
			
			&:after{
				left: 0;
			    width: 100%;
			    transform-origin: left center;
			}
		}
		@include respond('tab-port'){
			.icon-bx {
				img {
					width: 50px;
				}
			}
		}
		@include respond('phone'){
			padding: 25px;
			.icon-bx {
				img {
					width: 45px;
				}
			}
		}
	}

	&.pad-t{
		margin-top: 100px;
		@include respond('tab-land'){
			margin-top: 0;
		}
	}
}

.pad-m {
	margin-top: -86px;
	@include respond('tab-land'){
		margin-top: 0;
	}
}
@keyframes toTopFromBottom {
	49% {
		transform: translateY(-100%);
	}
	50% {
		opacity: 0;
		transform: translateY(100%);
	}
	51% {
		opacity: 1;
	}
}
@keyframes left-right {
	20% {
		transform: translateX(-5px) rotate(-5deg);
	}
	40% {
		transform: translateX(5px) rotate(5deg);
	}
	60% {
		transform: translateX(-5px) rotate(-5deg);
	}
	80% {
		transform: translateX(5px) rotate(5deg);
	}
	100% {
		transform: translateX(-5px) rotate(-5deg);
	}
}