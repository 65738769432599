:root{
	
	/* Primary */
	--primary: #ff3392;
	--primary-hover: #FFCEE6;
	--primary-dark: #FF3377;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(255,129,57,0.1);
	--rgba-primary-2: rgba(255,129,57,0.2);
	--rgba-primary-3: rgba(255,129,57,0.3);
	--rgba-primary-4: rgba(255,129,57,0.4);
	--rgba-primary-5: rgba(255,129,57,0.5);
	--rgba-primary-05: rgba(255,129,57,0.05);
	--rgba-primary-6: rgba(255,210,210,0.6);
	--rgba-primary-7: rgba(255,195,195,0.7);
	--rgba-primary-8: rgba(255,183,183,0.8);
	--rgba-primary-9: rgba(255,157,157,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #222222;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #E56AB3 1.12%, #F9A3CB 44%);
	--gradient-1: linear-gradient(90deg, #E56AB3 47.55%, #F9A3CB 100.15%);
	--gradient-2: linear-gradient(90deg, #E56AB3 0%, #EF87BE 100%);	
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #FCBCD7 47.55%, #E56AB3 100.15%);
	
	/* extra variable */
	/* --theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern1.svg); */
	
	--theme3-bg-color: #201916;
	--theme3-secondary: #301f18;
	--theme3-secondary2: #EF87BE;
	
}