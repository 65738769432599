#loading-area {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
	background-position:center;
	background-repeat:no-repeat;
	background-size:80px;
	background: #fff;
	display: flex;
    justify-content: center;
    align-items: center;
	
	&.loading-page-1{
		.loading-inner{
			.inner{
				text-align: center;
			}
			.item-circle{
				width: 400px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				
				@include respond ('phone'){
					width: 300px;
				}
			}
			.load-text{
				margin-top: 10px;
				display: flex;
				justify-content: center;
				.text-load{
					display: inline-block;
					color: rgba(0, 0, 0, 0.15);
					position: relative;
					font-weight: 700;
					font-size: 24px;
					font-family: var(--font-family-title);
					line-height: 30px;
					text-align: center;
					user-select: none;
					
					@include respond ('phone'){
						font-size: 20px;
					}
	
					&:before {
						animation: letters-loading 4s infinite;
						color: var(--title);
						content: attr(data-text);
						left: 0;
						opacity: 0;
						top:0;
						line-height: 30px;
						position: absolute;
					}
					&:nth-child(2):before{animation-delay: 0.2s;}
					&:nth-child(3):before{animation-delay: 0.4s;}
					&:nth-child(4):before{animation-delay: 0.6s;}
					&:nth-child(5):before{animation-delay: 0.8s;}
					&:nth-child(6):before{animation-delay: 1s;}
					&:nth-child(7):before{animation-delay: 1.2s;}
					&:nth-child(8):before{animation-delay: 1.4s;}
					&:nth-child(9):before{animation-delay: 1.6s;}
					&:nth-child(10):before{animation-delay: 1.8s;}
				}
			}
		}
	}
	&.loading-page-2{
		background-color: var(--secondary);
		.loading-inner{
			color: var(--primary);
			font-family: var(--font-family-title);
			font-size: 85px;
			font-weight: 700;
			text-align: center;
			display: flex;
			
			span{
				display: inline-block;
				opacity: 0;
				transform: rotateX(-90deg);
				position: relative;
				top: -100px;
				animation: dzTextAnimate 1.2s ease-in-out infinite;
				margin: 0 1px;
				
				&:nth-child(1){animation-delay: 1.2s;}
				&:nth-child(2){animation-delay: 1.3s;}
				&:nth-child(3){animation-delay: 1.4s;}
				&:nth-child(4){animation-delay: 1.5s;}
				&:nth-child(5){animation-delay: 1.6s;}
				&:nth-child(6){animation-delay: 1.7s;}
				&:nth-child(7){animation-delay: 1.8s;}
				&:nth-child(8){animation-delay: 1.9s;}
				&:nth-child(9){animation-delay: 2s;}
				&:nth-child(10){animation-delay: 2.1s;}
			}
			@include respond ('tab-port'){
				font-size: 48px;
				span{
					top: -50px;
				}
			}
			@include respond ('phone'){
				font-size: 36px;
			}	
		}
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: -100%;
			left: -100%;
			height: 300%;
			width: 300%;
			//background-image: url(../images/dz-blink.png);
			background-size: 900px;
			animation: dzBlinkEffect 1.5s steps(15) infinite;
			opacity: 0.4;
		}
	}
	&.loading-page-3{
		.loading-inner{
			background: var(--secondary);
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			transform: scale(1.2);
	
			.inner{
				width: 250px;
				height: 250px;
				position: relative;
			}
	
			.item1,
			.item2{
				content: "";
				width: 60px;
				height: 60px;
				border-radius:50%;
				left:50%;
				top:50%;
				position: absolute;
				background-color: var(--primary);
				transform:translate(-50%,-50%);
			}
			.item1{
				left:20%;
				animation: dzCircleBounceLeft 2.5s ease infinite;
			}
			.item2{
				left:80%;
				background-color: var(--secondary-2);
				animation: dzCircleBounceRight 2.5s ease infinite;
				z-index: 1;
			}
		}
	}
}

@keyframes dzTextAnimate{
	10%{
		opacity: 0.5;
	}
	20%{
		opacity: 1;
		top: 0;
		transform: rotateX(-360deg);
	}
	80%{
		opacity: 1;
		transform: rotateX(-360deg);
		top: 0;
	}
	90%{
		opacity: 0.5;
	}
	100%{
		opacity: 0;
		top: 100px;
	}
}

@keyframes dzCircleBounceLeft{
	0%{
		left:20%;
	}
	50%{
		left:50%;
	}
	100%{
		left:20%;
	}
}
@keyframes dzCircleBounceRight{
	0%{
		left:80%;
	}
	50%{
		left:50%;
	}
	100%{
		left:80%;
	}
}

@keyframes letters-loading{
	0%,
	75%,
	100% {
		opacity: 0;
		transform: rotateY(-90deg);
	}
	25%,
	50% {
		opacity: 1;
		transform: rotateY(0deg);
	}
}
@keyframes dzBlinkEffect{
	0%,
	100% {
		transform: translate(0, 0);
	}
	8%{
		transform: translate(-5%, -10%);
	}
	18%{
		transform: translate(-15%, 5%);
	}
	27% {
		transform: translate(7%, -25%);
	}
	42% {
		transform: translate(-5%, 25%);
	}
	48% {
		transform: translate(-15%, 10%);
	}
	55% {
		transform: translate(15%, 0);
	}
	68% {
		transform: translate(0, 15%);
	}
	82% {
		transform: translate(3%, 25%);
	}
	92% {
		transform: translate(-10%, 10%);
	}
}