// Blog single
.blog-single {
	margin-bottom:3.75rem;
	
	@include respond ('phone'){
		margin-bottom:2.5rem;	
	}
	
	.dz-post-meta {
		margin-bottom: 1.25rem;
	}
	.post-author img{
		height: 35px;
		width: 35px;
		border-radius: 100%;
		margin-right: 10px;
	}
	.dz-post-text {
		padding-bottom: 5px;
		&:after {
			content: "";
			display: block; 
			width: 100%;
			clear: both;
		}
	}
	.dz-post-tags {
		margin-top: 1.25rem;
	}
	.dz-social-icon{
	    display: flex;
		align-items: center;
		.title{
		    margin-right: 18px;
			margin-bottom: 0;
			line-height: 1.35;
		}
		ul li a{
			width: 35px;
			height: 35px;
			line-height: 35px;
			font-size: 16px;
			text-align: center;
			background-color: var(--rgba-primary-1);
			color: var(--primary);
			&:hover{
				background-color: var(--primary);
				color: #fff;
			}
		}
		ul li a i{
			width: 35px;
			height: 35px;
			line-height: 35px;
			font-size: 16px;
			text-align: center;
			background-color: var(--rgba-primary-1);
			color: var(--primary);
			&:hover{
				background-color: var(--primary);
				color: #fff;
			}
		}
	}
	.dz-info{
	    padding: 0;
	}
	.dz-post-media{
		border-radius:0.25rem;
	}
	.dz-media, 
	.dz-post-media {
		text-align:center
	}
	&.dz-card .post-tags{
		margin-left: -3px;
		margin-right: -3px;
	}
	&.style-1{
		.dz-media + .dz-info{
			padding: 25px 0 0;
		}
		.dz-info{
			padding: 0 0 30px;
			.blockquote{
				p{
					font-size: 20px;
					font-weight: 600;
				}
			}
			.post-comment {
				@include respond('phone'){
					display: none;
				}
			}
			.post-author {
				img {
					height: 35px;
					width: 35px;
					border-radius: 100%;
					margin-right: 10px;
					@include respond('phone-land'){
						height: 27px;
						width: 27px;
						margin-right: 5px;
					}
				}
			}
		}
	}
}
.wp-block-code>code {
    color: #fff;
}
.dz-post-text{
	.widget_archive li a, 
	.wp-block-latest-posts li a, 
	.wp-block-categories-list li a, 
	.wp-block-archives-list li a, 
	.widget_categories li a,
	blockquote a,
	.wp-block-button__link{
		box-shadow:none;
		text-decoration:none;
	}
	iframe {
		max-width: 100%;
	}
}
.post-header{
	position:relative;
	overflow:hidden;
	@include respond('phone'){
		margin-bottom:1.25rem;
	}
	.dz-media{
		img{
			min-height:250px;	
			object-fit:cover;
			width: 100%;   
		}
	}
	.dz-info{
		position:absolute;
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 1%, rgba(0,0,0,0.6) 100%);
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 1%,rgba(0,0,0,0.6) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 1%,rgba(0,0,0,0.6) 100%);
		position: absolute;
		bottom: 0;
		text-align: center;
		padding: 100px 30px 30px 30px !important;
		width: 100%;
			
		@include respond('phone'){
			padding: 40px 15px 15px 15px !important;
		}	
		.dz-title{
			color:#fff;	
			@include respond('phone'){
				font-size:1.125rem;
			}
		}
		.dz-meta ul li{ 
			&:after{
				background:#fff;
			}
			strong,
			span, 
			a{
				color:#fff;
			}
		}
	}
}
.post-link-in {
    padding: 15px 50px;
    background: rgba(0,0,0,0.7);
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    left: 0;
	@include transitionMedium;
	&:hover{
		background:var(--primary);
		color:#fff;
	}
}
.side-bar {
    padding-left: 20px;
	padding-bottom: 1px;
	.wp-block-search, 
	.wp-block-archives, 
	.wp-block-latest-posts, 
	.wp-block-latest-comments, 
	.wp-block-categories, 
	.wp-block-calendar{
		margin-bottom:0;
	}	
	@include respond('tab-port'){
		 padding-left: 0;
		 padding-right: 0;
	}
	&.left{
		padding-left: 0;
		padding-right: 20px;
		@include respond('tab-port'){
			 padding-right: 0;
			 padding-left: 0;
		}
	}
}
// WP DEFAULT ==============
// > ALIGN NONE
.alignnone {
    margin: 0.3125rem 0 1.563rem 0;
	
	&img,
	&.wp-caption,
	&.is-resized{
		margin: 0.3125rem 0 1.563rem 0;
	}
}
// > ALIGN CENTER
.aligncenter{
    display: block;
    margin: 0.3125rem auto 0.938rem;
	text-align:center;
	&img,
	&.wp-caption,
	&.is-resized{
		display: block;
		margin: 0.3125rem auto 0.938rem;
		text-align:center;
	}
}
// > ALIGN RIGHT
.alignright {
    float: right;
    margin: 0.3125rem 0 1.563rem 1.563rem;
	&img,
	&.wp-caption,
	&.is-resized{
		margin: 0.3125rem 0 1.563rem 1.563rem;
		float: right;
	}
}
// > ALIGN LEFT
.alignleft{
    float: left;
    margin: 0.3125rem 1.563rem 1.563rem 0;
	&img,
	&.wp-caption,
	&.is-resized{
		margin: 0.3125rem 1.563rem 1.563rem 0;
		float: left;
	}
}

.wp-caption {
    max-width: 100%;
    text-align: center;
	img[class*="wp-image-"] {
		display: block;
		margin: 0;
	}
	img {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 100%;
		padding: 0;
		width: auto;
	}
	p.wp-caption-text {
		font-size: 0.813rem;
		line-height: 1.125rem;
		margin: 0;
		padding: 0.625rem 0;
		text-align: left;
	}
}
// BLOCKQUOTE 

.wp-block-quote.is-large, 
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright, 
blockquote.wp-block-pullquote.alignleft,
blockquote{
    padding: 1.5rem 2rem 1.5rem 2.5rem;
    font-size: 0.938rem;
    color: var(--title);
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.875rem;
    position: relative;
    clear: both;
    font-weight: 700;
	z-index:1;	
	border:0;
	background-color: var(--rgba-primary-1);
	
	@include respond('tab-port'){
		padding: 1.25rem 1.25rem 1.25rem 2.25rem;
		font-size: 0.813rem;
	}
	@include respond('phone'){
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
	cite{
		font-style: normal;
		position: relative;
		display: block;
		margin-top: -0.3125rem;
		font-weight: 600;
		color:var(--primary);	
		line-height: 1.3;
		font-size: 14px;
		
		&:before{
			content: "";
			margin-right: 0.625rem;
			width: 15px;
			height: 2px;
			background: var(--primary);
			display: inline-block;
			vertical-align: middle;
		}
	}
	b, strong, .strong{
		color:inherit;	
	}
	&:after{
		content: "";
		width: 5px;
		height: 100%;
		left: 0;
		top: 0;
		background: var(--primary);
		position: absolute;
		box-shadow: 0 0 30px 0 var(--rgba-primary-2);
	}
	
	&:before {
		content: "";
		z-index: -1;
		background-size: cover;
		left: 20px;
		background-image: var(--quote);
		background-position: center right;
		width: 90px;
		padding: 0;
		opacity: 0.1;
		background-repeat: no-repeat;
		height: 90px;
		top: 0px;
		position: absolute;
		
	}
	&.wp-block-pullquote.alignleft{
		margin: 0rem 1.563rem 1.563rem 0rem;
	}
	&.wp-block-pullquote.alignright{
		margin: 0rem 0 1.563rem 1.563rem;
	}
	
	&.style-1{
		background: #fff;
		color: var(--primary);
		padding: 25px 40px;
		background-color: var(--rgba-primary-1);
		
		p{
			font-size:24px;
			line-height:1.5;
			color:var(--title);
		}
		&:before{
			left: 20px;
			background-size: contain;
			width: 80px;
			top: 0;
			transform: rotate(180deg);
			background-image: var(--quote-2);
		}
		cite{
			color:var(--primary);
			&:before{
				background:var(--primary);
			}
		}
	}
	
}
blockquote.wp-block-pullquote:not(.is-style-solid-color){
    background: #5608e0;
}
.wp-caption-text {
    font-size: 0.875rem;
    line-height: 1.3;
}
.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
	font-size: 18px;
    line-height: 1.5;
    color: var(--title);
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-style: italic;
	
	cite{
		margin-top:20px;
	}
	@include respond('tab-port'){
		font-size:1.125rem;
	}
	@include respond('phone'){
		font-size: 1rem;
		margin-bottom: 1rem;
	}
}
.dz-page-text{
	padding-bottom:30px !important;
}
.dz-card.blog-single .dz-post-text blockquote,
.dz-page-text blockquote,
blockquote{
	&.style-1{
		p{
			margin-bottom: 1.5rem;
		}
	}
}
.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto;
}


/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-card.blog-single,
.dz-card.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links{
	max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
	padding-left: 0.938rem;
    padding-right: 0.938rem;
}
.dz-card.blog-single.sidebar + #comment-list{
	padding:0;
	max-width:100%;
}
.max-container{
	max-width: 93.75rem;
    margin-left: auto;
    margin-right: auto;
	padding-left: 3.4375rem;
    padding-right: 3.4375rem;
}
.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text{
	padding:0;
}
.dz-page-text.sidebar{
	width:100%;
	padding:0;
	max-width:100%;
}
.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul{
    list-style:none;
	margin-bottom: 1.875rem;
}
.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
    padding-left: 1.25rem;
	ol,
	ul{
		padding-left:1.563rem;
	}
}
.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
	padding: 0.5rem 0.5rem;
	position: relative;
	list-style: inherit;
}
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
	padding: 0;
	list-style: none;
}
.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li{
	padding: 0;
	list-style: none;
	margin:0.25rem;
}
.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before{
	content:none;
}
.wp-block-gallery .blocks-gallery-image, 
.wp-block-gallery .blocks-gallery-item{
	margin: 0 0.5rem 0.5rem 0;
}
.wp-block-gallery.columns-4 .blocks-gallery-image, 
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image, 
.wp-block-gallery.columns-3 .blocks-gallery-item{
	margin-right: 0.5rem;
}
.content-spacious-full .wp-block-gallery {
    margin-left: calc(-12.5rem - (0.313rem/2));
    margin-right: calc(-12.5rem - (0.313rem/2));
    width: calc(100% + 25rem + 0.313rem);
    max-width: initial;
}
.alignfull,
.alignwide{
	margin-left: calc(-6.25rem - (0.313rem/2));
    margin-right: calc(-6.25rem - (0.313rem/2));
    width: calc(100% + 12.5rem + 0.313rem);
    max-width: initial;
}
.alignfull,
.alignfullwide{
	margin-left: calc(50% - 50vw - (var(--content-share-ml,0px)/2));
	width: 100vw;
	max-width: 100vw;
	box-sizing: border-box;
}
.dz-page-text h1,
.dz-post-text h1,
.thm-unit-test .dz-post-text h1 {
    margin-bottom: 1rem;
	font-weight: 700;
}
.dz-page-text h2,
.dz-post-text h2,
.thm-unit-test .dz-post-text h2 {
    margin-bottom: 1rem;
	font-weight: 700;
}
.dz-page-text h3,
.dz-post-text h3,
.thm-unit-test .dz-post-text h3 {
    margin-bottom: 1rem;
	font-weight: 700;
}
.dz-page-text h4,
.dz-post-text h4,
.thm-unit-test .dz-post-text h4 {
    margin-bottom: 0.75rem;
	font-weight: 600;
}
.dz-page-text h5,
.dz-post-text h5,
.thm-unit-test .dz-post-text h5 {
    margin-bottom: 0.75rem;
}
.dz-page-text h6,
.dz-post-text h6,
.thm-unit-test .dz-post-text h6 {
    margin-bottom: 0.625rem;
}
.wp-block-code,
pre.wp-block-code,
pre {
	margin: 0 0 1.563rem;
    padding: 1.25rem;
    color: #fff;
    background-color: #212326;
    white-space: pre;
    font-size: 0.938rem;
    border-radius: 0.1875rem;	
}
.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single .dz-card.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right){
	font-size:1rem;
	line-height:1.7;
}
.wp-block-cover, 
.wp-block-cover-image{
	color:#fff;
}
.wp-block-cover p:last-child, 
.wp-block-cover-image p:last-child{
	margin-bottom:0;
}
.wp-block-quote.has-text-align-right{
	border-right:0;
}
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li{
	position:relative;
}
.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before{
    content: "";
    display: inline-block;
    width: 0.375rem;
    height: 0.375rem;
    background:$dark;
    left: -0.938rem;
    position: absolute;
    top: 1.063rem;
	border-radius: 4px;
}
.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before{
	content:none;
}
.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
	margin-bottom:1.65rem;
	
}
//.dz-page-text .wp-block-gallery,
//.dz-card.blog-single .dz-post-text .wp-block-gallery {
//    margin-left: calc(-11.875rem - (0.625rem/2));
//    margin-right: calc(-11.875rem - (0.625rem/2));
//    width: calc(100% + 23.75rem + 0.625rem);
//    max-width: initial;
//}
.paginate-links{ 
	display: flex;
    align-items: center;
    margin: 1.5rem 0 4rem 0;
	& > a, 
	& > span {
		margin: 0 0 0 0.625rem;
		position: relative;
		border: 0rem solid #6cc000;
		color: #777777;
		display: inline-block;
		font-size: 1rem;
		font-weight: 500;
		line-height: 50px;
		min-width: 50px;
		height: 50px;
		text-align: center;
		text-transform: capitalize;
		transition: all 500ms ease 0s;
		background: #f3f4f6;
		border-radius: var(--border-radius-base);
	}
	.current{
		color: #fff;
		background-color: var(--primary);
		border-color: var(--primary);
		box-shadow: 0px	5px 12px var(--rgba-primary-4);
	}
}
.wp-block-columns{
	margin-bottom:0;
}
/* Gutenberg Gallery */
.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 1rem 1rem 0;
    position: relative
}
.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0
}
@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto;
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3) 60%, transparent);
    bottom: 0;
    color: #fff;
    font-size: 0.813rem;
    max-height: 100%;
    overflow: auto;
    padding: 2.5rem 0.625rem 0.3125rem;
    position: absolute;
    text-align: center;
    width: 100%
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 0.5rem)
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%
}

@media only screen and (max-width: 64rem) {
	.alignwide{
		margin-left: 0;
		margin-right:0;
		width: 100% !important;
		max-width: 100% !important;
	}
	.alignwide .alignleft img{
		width: 100%;
	}
	
	.alignwide .alignleft{
		margin: 0.3125rem 0rem 1.563rem 0;
		float: none;
	}
}
@media (min-width:37.5rem) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(33.33333% - 0.666rem)
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(25% - 0.75rem)
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(20% - 0.8rem)
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(16.66667% - 0.833rem)
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(14.28571% - 0.857rem)
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(12.5% - 0.875rem)
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%
}
.wp-block-image.alignfullwide img{
	border-radius:0;
}
.wp-block-image img{
	border-radius:0.625rem;
}
.wp-block-cover, 
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
    margin-bottom: 1.875rem;
	margin-top: 1.875rem;
}
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 19.0625rem;
    width: 100%
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center
}
/* .dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
    margin-left:0;
    margin-right:0;
    width: 100%;
    max-width: initial;
} */
.blog-overlap {
    background: #fff;
    margin-top: -8.75rem;
    padding: 1.25rem 1.25rem 0;
	border-radius: 0.25rem 0.25rem 0rem 0rem;
}
.blog-single.dz-card.sidebar{
	width:100%;
	max-width: 100%;
	padding-left:0;
	padding-right:0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-size: 1rem;	
	margin-bottom: 1.875rem;
	@include respond('phone'){
		font-size:0.875rem;
	}
}
.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption{
    font-size: 0.875rem;
    font-family:'Poppins', sans-serif;
    font-style: normal;
	margin-top:0.625rem;
}
.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
    background-color: #fafafa;
}
.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td, 
.wp-block-table th {
    padding: 0.625rem 0.938rem;
    border: 0.0625rem solid #e4e4e4;
    border-right: 0;
    border-left: 0;
	@include respond('phone'){
		padding: 0.5rem 0.5rem;
	}
}

.wp-block-media-text{
	margin-bottom:30px;
	.wp-block-media-text__content {
		padding: 0 1.875rem;
	}
}
.wp-block-column ol li:before,
.wp-block-column ul li:before{
	content:none !important;
}
.wp-block-pullquote {
	padding: 1em 0;
	border-top:0;
	border-bottom:0;
	//cite {
	//	color: #fff;
	//}
	&.alignright, 
	&.alignleft {
		padding: 0;
		border-top:0;
		border-bottom:0;
		blockquote {
			margin: 0;
		}
	}
}

.dz-post-text .wp-block-cover-image-text, 
.dz-post-text .wp-block-cover-text, 
.dz-post-text section.wp-block-cover-image h2,
.dz-page-text .wp-block-cover-image-text, 
.dz-page-text .wp-block-cover-text, 
.dz-page-text section.wp-block-cover-image h2 {
    color: #fff;
}
.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
    box-shadow: none;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
}
.dz-post-text .wp-block-calendar a{
	box-shadow: none !important;
    text-decoration: unset;	
}
.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar{
	margin-bottom:2.5rem;
}
/* Admin Bar */
.admin-bar .is-fixed .main-bar{
	top: 1.875rem;
}
.wp-block-quote.is-style-large cite:before{
	display:none
}

/* Theme Unit Test */
.post-password-form{
	label{
		font-size: 1rem;
	}
	input[type="submit"] {
		padding: 0.625rem 1.563rem;
		background-color:var(--primary);
		border: none;
		height: 2.8125rem;
		font-weight: 600;
		font-size: 0.875rem;
		outline:none;
	}
	input[type="submit"]:hover{
		background-color:var(--primary);
	}
	input[type="password"]{
		height: 2.8125rem;
		border: 0.0625rem solid #ced4da;	
	}
}

// WP BLOCK SEARCH
.wp-block-search{
	.wp-block-search__button{
		background: var(--primary);
		border: 0;
		color: #fff;
		font-size: 0.875rem;
		text-transform: uppercase;
		font-weight: 600;
		padding: 0.625rem 1.563rem;
		@include transitionSlow;
	}
	.wp-block-search__input{
		border: 0.0625rem solid #e1e6eb;
		height: 2.8125rem;
		padding: 0.625rem 1.25rem;
		font-size: 0.875rem;
		outline: none;
	}
}

.wp-block-tag-cloud{
	a{
		position: relative;
		border: 2px solid;
		padding: 10px 20px;
		display: inline-block;
		margin: 0 10px 10px 0;
		font-size: 14px;
		color: inherit;
		line-height: 1.4;
		border-color: rgba(0,0,0,0.1);
        border-radius: var(--border-radius-base);
		&:hover{
			background-color: var(--primary-hover);
			border-color:var(--primary-hover);
			color:#fff;
		}
	}
}
// Comments Avatar
.wp-block-latest-comments {
	.avatar {
		width: 3.4375rem;
		height: 3.4375rem;
		border-radius: 3.4375rem;
	}
	.wp-block-latest-comments__comment {
		.wp-block-latest-comments__comment-excerpt {
			margin-left: 4.375rem;
		}
		.wp-block-latest-comments__comment-meta {
			color: inherit;
			font-size: 1.063rem;
			line-height: 1.7;
			margin-bottom: 0;
			a {
				color: inherit;
				box-shadow: none;
				text-decoration: none;
			}
		}
	}
	img {
		+ {
			article {
				.wp-block-latest-comments__comment {
					.wp-block-latest-comments__comment-meta {
						margin-left: 4.375rem;
					}
				}
			}
		}
	}
	padding: 0 !important;
	.wp-block-latest-comments__comment-meta {
		time {
			display: block;
			width: 100%;
			color: #9fa1a4;
			font-size: 0.813rem;
			font-weight: 400;
		}
	}
	.wp-block-latest-comments__comment-excerpt {
		p {
			font-size: 1rem !important;
			line-height: 1.5 !important;
			margin-bottom: 0.625rem !important;
		}
	}
	li {
		&:before {
			content: none !important;
		}
		padding: 0 !important;
		border-bottom: 0.0625rem solid #eee;
		padding-bottom: 0.625rem !important;
		margin-bottom: 0.625rem;
	}
}
.wp-block-latest-comments__comment-avatar {
	width: 3.4375rem;
	height: 3.4375rem;
	border-radius: 3.4375rem;
}
.comment-content.dz-page-text{
	max-width:100%;
	& > :last-child{
		margin-bottom:0;
	}
}
// WP BLOCK RSS
.wp-block-rss{
	padding: 0 !important;
	.wp-block-rss__item{
		padding: 0.3125rem 0 !important;
		border-bottom: 0.0625rem solid #eee;
		&:before{
			content:none !important;
		}
		a{
			font-family: 'Poppins', sans-serif;
			font-size: 1.125rem;
			box-shadow: unset !important;
			font-weight: 600;
			color: var(--title);
			text-decoration: none;
		}
	}
}
@media only screen and (max-width: 75rem){
	.dz-page-text .wp-block-gallery,
	.dz-card.blog-single .dz-post-text .wp-block-gallery{
		margin-left: calc(-6.25rem - (0.625rem/2));
		margin-right: calc(-6.25rem - (0.625rem/2));
		width: calc(100% + 12.5rem + 0.625rem);
	}
}
@media only screen and (max-width: 61.9375rem) {
	.admin-bar .mo-left .header-nav{
	    top: 1.875rem;
		height: calc(100vh - 1.875rem) !important;	
	}
	
	.dz-page-text  .wp-block-gallery,
	.dz-card.blog-single .dz-post-text .wp-block-gallery {
		margin-left:0;
		margin-right:0;
		width:100%;
	}
	.dz-page-text, 
	.dz-page-text ul li, 
	.dz-page-text ol li, 
	.dz-page-text p,
	.dz-card.blog-single .dz-post-text, 
	.dz-card.blog-single .dz-post-text ul li, 
	.dz-card.blog-single .dz-post-text ol li, 
	.dz-card.blog-single .dz-post-text p{
		font-size:1rem;
		@include respond('phone'){
			font-size:0.875rem;
		}	
	}
}
@media only screen and (max-width: 48.875rem) {
	.admin-bar .is-fixed .main-bar{
		top: 2.8125rem;
	}
	.admin-bar .mo-left .header-nav{
	    top: 2.8125rem;
		height: calc(100vh - 2.812rem) !important;	
	}
	.wp-block-media-text{
		display: block;	
	}
	.wp-block-media-text .wp-block-media-text__media{
		margin-bottom:0.938rem;
	}
	.wp-block-media-text .wp-block-media-text__content {
		padding: 0;
	}
}
@media only screen and (max-width: 37.5rem) {
	.admin-bar .is-fixed .main-bar{
		top: 0;
	}
	.admin-bar .mo-left .header-nav{
	    top: 2.8125rem;
		height: calc(100vh - 2.812rem) !important;	
	}
	.admin-bar .mo-left .is-fixed  .header-nav{
	    top: 0;
		height:100vh !important;	
	}
}

// POST FOOTER
.post-footer {
	border-top: 0.0625rem solid rgba(0,0,0,.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.938rem 0 0;

	.dz-meta {
		.tag-list {
			padding-bottom: 0;
			text-align: left;
			margin: 0;
			padding: 0;
			list-style: none;
			text-transform: capitalize;	
			display: inline;	
		}
		ul li {
			margin-right: 0.3125rem;
			padding: 0;
			display: inline-block;
			color: #333333;
			font-weight: 500;
			font-size: 0.938rem;
			font-style: italic;	
		}
	}
	.post-tag a {
		text-transform: capitalize;
		font-size: 0.938rem;
		color: #999;
		background: transparent;
		padding: 0;
		border: 0;
		line-height: 0.875rem;
		font-weight: 500;
	}
}
.share-post{
	li{
		display:inline-block;
	}
	a{
		width:2.1875rem;
		height:2.1875rem;
		border-radius:2.1875rem;
		border:0.0625rem solid #eee;
		display:block;
		text-align:center;
		line-height:2.0625rem;
		color:$dark
	}
}


// .extra-blog 
.extra-blog{
	margin-bottom: 60px;
}
.comment-respond.style-1{
	.comment-reply-title{	
		@include respond('phone'){
			margin-bottom: 20px;
		}
	}
}
.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title{
	font-size: 23px;
	font-weight: 800;
}
.default-form{
	@include respond('tab-port'){
		margin-bottom: 40px;
	}
	@include respond('phone-land'){
		margin-bottom: 30px;
	}
}

// comments-area
.comments-area{
	.comment-list{
		margin-bottom: 60px;
		padding: 0;
		@include respond('phone-land'){
			margin-bottom: 40px;
		}
		.default-form{
			margin-top:3.5rem;
			margin-bottom:3.5rem;
			small {
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 1px;
				a{
					color:#e10000;	
				}
			}
		}
		.dz-page-text {
			padding-bottom: 0 !important;
		}
		&>.comment{
			.comment-body{
				position: relative;
				padding: 0 0 20px 130px;
				margin-bottom: 30px;
				min-height: 125px;
				border-bottom: 2px solid rgba(0, 0, 0, 0.1);
				
				@include respond('phone-land'){
					padding: 0 0 25px 75px;
				}
				@include respond('phone'){
					padding: 0 0 20px 75px;
				}
				.comment-author{
					position: absolute;
					left: 0;
					height: 105px;
					width: 105px;
					border-radius: var(--border-radius-sm);
					
					@include respond('phone-land'){
						height: 60px;
						width: 60px;
					}
					&:after{
						content: "";
					    position: absolute;
					    height: 100%;
					    width: 100%;
					    transform: rotate(8deg);
					    left: 0;
					    z-index: -1;
					    top: 0;
					    border: 2px solid var(--primary);
					}
				}
				.comment-info{
					.title{
						margin-bottom: 8px;
						span{
							font-size: 15px;
						}
					}
					.fn{
						font-size: 16px;
						line-height: 1.3;
						color: var(--title);
						font-weight: 600;
						font-style: normal;
						margin-bottom: 3px;
						display: block;
						font-family: var(--font-family-title);
					}
				}
				.reply{
					.comment-reply-link{
						font-weight: 500;
						text-transform: uppercase;
						font-size: 12px;
						background: var(--primary);
						color: #fff;
						padding: 6px 10px 4px 10px;
						display: inline-block;
						line-height: 1.3;
						@include transitionMedium;
						position: absolute;
						right: 0;
						top: 0;
						transform: skewX(-12deg);
						span {
							transform: skewX(12deg);
							display: inline-block;
						}
						&:hover{
							background: var(--primary-hover);
						}	
						i{
							margin-right: 8px;
							font-size: 14px;
						}
						@include respond('phone-land'){
							position: unset;
						}
					}
				}
			}
			&:last-child{
				border-bottom: 0;
				margin-bottom: 0;
			}
			&>.children{
				padding-left: 50px;
				@include respond('tab-port'){
					padding-left: 30px;
				}
				@include respond('phone'){
					padding-left: 15px;
				}	
			}
		}	
		.comment-form{
			margin-bottom: 40px;
		}
	}
}
.comment-reply-title a{
    font-size: 14px;
    font-weight: 400;
}
.comment-respond{
	small{
		font-size: 14px;
		margin-left: 7px;
		a{
			color: #ef060f;
		}
	}
	.comment-form{
		display: flex;
		flex-wrap: wrap;
		margin-left: -10px;
		margin-right: -10px;
		p{
			margin-bottom: 30px;
			padding-left: 10px;
			padding-right: 10px;
			width: 50%;
			
			@include respond ('phone'){
				margin-bottom: 15px;			
			}
			label{
				display: none;
			}
			&.comment-form-author input,
			&.comment-form-email input,
			&.comment-form-url input,
			textarea{
				height: 60px;
				border: 3px solid #D9D9D9;
				border-width: 0 0 2px 0;
				padding: 15px 0;
				font-size: 18px;
				font-weight: 400;
				color: #555;
				@include transitionMedium;
				background: transparent;
				width: 100%;
				border-radius: var(--border-radius);
				
				&:focus,
				&:active{
					background: #fff;
					border-color: var(--primary);
				}
				@include respond ('phone'){
					font-size: 16px;				
				}
			}
			textarea{
				height: 120px !important;
			}
			&.comment-form-url{
				width: 100%;
			}
		}
		.comment-form-comment{
			width: 100%;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	
	@include respond('phone'){
		.comment-form{
			.comment-form-author,
			.comment-form-email,
			.comment-form-url{
				width: 100%!important;
			}
		}	
	}
}
.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
    clear: both;
    margin: 0 auto;
    overflow: hidden
}
.gallery{
	.gallery-item {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		padding: 0.3125rem;
		position: relative;
		box-sizing: border-box;
		img {
			float: left;
			padding: 0 0rem;
			width: 100%;
			border: none !important;
		}
	}
	.gallery-caption {
		font-size: 0.813rem;
		color: #707070;
		display: block;
		font-family: "Noto Sans", sans-serif;
		line-height: 1.5;
		padding: 0.5em 0;
		clear: both;
	}
	
}
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none;
}
.gallery-columns-2 .gallery-item {
    max-width: 50%;
}
.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}
.gallery-columns-4 .gallery-item {
    max-width: 25%;
}
.gallery-columns-5 .gallery-item {
    max-width: 20%;
}
.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}
.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}
.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}
.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}
.gallery-icon img {
    margin: 0 auto;
}

.post-password-form {
    position: relative;
    clear: both;
	label {
		display: block;
		font-size: 1rem;
	}
	input[type="password"] {
		width: 100%;
		border: 0.0625rem solid #ebedf2;
		padding: 0.625rem 6.25rem 0.625rem 0.938rem;
		height: 2.8125rem;
		border: 0.0625rem solid #ced4da;
	}
	input[type="submit"] {
		position: absolute;
		bottom: 0;
		right: 0;
		padding: 0.625rem 1.25rem;
		background: var(--primary);
		color: #FFF;
		border: none;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 0.875rem;
		outline:none;
		height: 2.8125rem;
		&:hover{
			background:var(--primary-hover);
		}
	}
}
@include respond('tab-port'){
	.side-bar.sticky-top{
		position:unset;
	}
}
.blog-post-banner{
	min-height:300px;
	padding-top:70px;
	padding-bottom:70px;
	.dz-title{
		color: #fff;
		font-size: 32px;
		margin-bottom:20px;
	}
	.dz-meta{
		color:#fff;
		ul{
			display:flex;
			opacity:0.7;
			li{
				margin-right:20px;
				a{
					color:#fff;	
				}
			}
		}
	}
}
.post-outside{
    margin-top: -120px;	
}
.single-post .main-bar {
    border-bottom: 1px solid #eee;
}
.dz-load-more i, 
.loadmore-btn i{
	&:before{
		-webkit-animation: unset !important;
		-moz-animation: unset !important;
		animation: unset !important;	
	}
}
.dz-share-post{
	border-top: 1px solid #cccccc;
    border-top-style: dashed;
	display:flex;
	align-items:center;
	justify-content:space-between;
	padding: 10px 0;
	
	.post-tags{
		a{
			position: relative;
			border: 1px solid;
			padding: 5px 12px;
			display: inline-block;
			margin: 3px;
			font-size: 13px;
			color: inherit;
			line-height: 1.4;
			border-color: rgba(175, 163, 163, 0.4);
			border-radius: 0;
			@include transitionMedium;
			span {
				display: inline-block;
			}
			&:hover{
				box-shadow: 0px 5px 12px var(--rgba-primary-4);
				background-color: var(--primary-hover);
				border-color: var(--primary-hover);
				color: #fff;
			}
		}
	}
	@include respond ('phone-land'){
		display:block;
		.post-tags{
			margin-bottom:20px;
		}
	}
	@include respond ('phone'){
		.post-tags a{
			margin-right:5px;
		}
	}
}

#comment-list:empty + .paginate-links {
    margin-top: -30px;
}


.post-swiper,
.post-swiper-thumb,
.service-swiper{
	position:relative;
	.btn-prev,
	.btn-next,
	.prev-post-swiper-btn,
	.next-post-swiper-btn,
	.prev-service-swiper-btn,
	.next-service-swiper-btn{
		position:absolute;
		top:50%;
		transform:translateY(-50%) skewX(-10deg);
		height:45px;
		width:45px;
		line-height:45px;
	    border-radius: 0;
		text-align:center;
		font-size:20px;
		background-color:#fff;
		color:var(--primary);
		z-index:1;
		margin: 0 10px;
		@include transitionMedium;
		&:hover{
			background-color:var(--primary);
			color:#fff;
		}
		i {
			transform: skewX(10deg);
		}
	}
	.btn-prev,
	.prev-post-swiper-btn,
	.prev-service-swiper-btn{
		left:0;
	}
	.btn-next,
	.next-post-swiper-btn,
	.next-service-swiper-btn{
		right:0;
	}
	.swiper-slide img{
		width:100%;
	}
}

.service-swiper{
	img{
		border-radius:var(--border-radius-base);
	}
}

.blog-single .dz-media + .dz-info {
    padding: 30px 0 0;
}
// Author Box
.author-box{
    padding: 35px;
    background: #f3f4f6;
    border-radius:var(--border-radius);
    margin-bottom: 60px;
	
	.author-profile-info{
		display:flex;
	    align-items: center;
			
		@include respond('phone'){
			display:block;
			text-align:center;
		}
		.author-profile-pic{
			width: 150px;
			height: 150px;
			overflow: hidden;
			min-width: 150px;
			@include respond('phone'){
				width:80px;
				height:80px;
				min-width:80px;
				margin-bottom:20px;
				margin-left:auto;
				margin-right:auto;
			}
			img{
				width:100%;
			}
		}
		.author-profile-content{
			padding-left:25px;
			@include respond('phone'){
				padding-left:0;
				padding-right:0;
			}
			p{
			    margin-bottom: 15px;
			}
			ul{
				padding: 0;
				margin: 0;
				margin-right: 5px;
				display: table;
				float: left;
				
				@include respond('phone'){
					display: inline-block;
					float: none;
					margin-right: 0;
				}
				li{
					padding: 0;
					margin: 0;
					margin-right:10px;
					float: left;
					@include respond('phone'){
						margin-right:5px;
						margin-left:5px;
					}
					a{
						height: 40px;
						width: 40px;
						line-height: 40px;
						text-align: center;
						font-size: 14px;
						background: var(--primary);
						color: #fff;
						vertical-align: middle;
						display: block;
						@include transitionMedium;
						border-radius: var(--border-radius-sm);
						&:hover{
							background: var(--primary-hover);
						}
					}
				}
			}
		}
	}
}
#comment-list:empty + .paginate-links {
    margin-top: -30px;
}
.bypostauthor{font-size:normal;}
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	word-wrap: normal !important;
	&:focus {
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000;
	}
}
.wp-block-columns.alignfull,
.alignfull:not(.has-background) .wp-block-columns {
    padding-left: 1rem;
    padding-right: 1rem;
}