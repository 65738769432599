// PRICING TABLE
.pricingtable-features {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
		padding: 12px;
		i{
			margin: 0 3px;
		}
	}
	
}
.no-col-gap .pricingtable-wrapper {
    margin-left: -1px;
}

// Table =======
.table-option{
	border: 2px solid #adadad;
	border-radius: 6px;
	padding:50px 0px 30px 0;
	
	.pricingtable-footer{
		border:0;
		background-color: transparent;
	}
	.pricingtable-features{
		border:0;
		background-color: transparent;
		li{
			border:0;
			background-color: transparent;
			color:#919191;
			font-size:18px;
		}
	}
	.pricingtable-title{
		border:0;
		background-color: transparent;
		padding: 0;
		
		*{
			color:#000;
		}
		h2{
			font-size: 28px;
			line-height: 36px;
		}
	}
	.pricingtable-price{
		padding: 0;
		border:0;
		background-color: transparent;
	}
	.pricingtable-bx {
		color: #000;
		font-size: 63px;
		font-weight: 800;
		
		strong, sup{
			font-size: 30px;
			font-weight: 700;
		}
	}
	&:hover,
	&.active{
		background-color:#eeeeee;
		border: 2px solid #eeeeee;
	}
	&.dark{
		@include transitionMedium;
		
		&:hover,
		&.active {
			background-color: #404040;
			border: 2px solid #404040;
			box-shadow: 0 0 10px 5px rgba(0,0,0,0.3);
			transition: all 0.5s;
			.pricingtable-features li,
			.pricingtable-title h2,
			.pricingtable-price .pricingtable-bx{
				color: #fff;
				@include transitionMedium;
			}
			.pricingtable-footer .btn.black{
				background-color: #fff;
				border-color: rgba(0,0,0,0);
				color: #000;
			}
		}
	}
}

// pricingtable-toggle
.pricingtable-toggle{
	display: flex;
    margin: auto;
    justify-content: center;
    padding: 0;
    margin-bottom: 10px;
	span{
	    font-size: 18px;
		margin-top: 5px;
		font-weight: 500;
	}
	.custom-control-label{
		margin: 0 35px 0 50px;
	}
}


// Pricing Table
.pricingtable-wrapper{
	box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
	border-radius: var(--border-radius-base);
	overflow: hidden;
	@include transitionMedium;
	background-color: $white;
	
	&.style-1{
		background-color: $dark;
		padding: 65px 43px 57px;
		position:relative;
		z-index:1;
		border-radius: 0;
		height:100%;
		
		&:after ,
		&:before {
		    content: '';
		    position: absolute;
		    height: 42%;
		    z-index: -1;
		    bottom: 0;
		    right: 0;
		    @include transitionMedium;
		    background-size: cover;
		    background-repeat: no-repeat;
		}
		&:after {
			background-image: url(../images/background/bg-pricetable-1.svg);
	        width: 75%;
   		 	height: 90%;
		}
		&:before {
			background-image: url(../images/background/bg-pricetable-2.png);
		    width: 40%;
		    opacity: 0.2;
		}
		.pricingtable-inner{
			display: flex;
			flex-direction: column;
			height: 100%;
		}
		.pricingtable-footer{
			margin-top:auto;
		}
		.pricingtable-price{
			margin-bottom: 10px;
			.pricingtable-bx{
				font-weight: 600;
				font-size:65px;
				margin: 0;
				small{
					font-size:16px;
					color: var(--theme-text-color);
					font-weight: 500;
				}
				@include custommq ($max:1200px){
					font-size:45px;
				}
			}
			p {
				margin:0;
				margin-bottom: 15px;
				font-size:15px;
				color: #999999;
				line-height: 1.9;
			}
			.pricingtable-type{
				color:#071c35;
				font-weight: 700;
				font-size: 26px;
				font-family:var(--font-family-title);				
			}
		}
		.pricingtable-features{
			border-top:1px solid;
			border-color: rgba(255, 255, 255, 0.2);
			padding-top:15px;
			padding-bottom:15px;
			text-align: left;
			li{
				padding: 5px 0 5px 40px;
				position:relative;
				margin:10px 0;
				color: #fff;
				&:after {
					content: "\f00c";
					font-family: 'Font Awesome 5 Free';
					font-size: 12px;
					font-weight: 600;
					width: 24px;
					height: 24px;
					background: rgba(255, 255, 255, 0.2);
					display: block;
					position: absolute;
					left: 0;
					color: #fff;
					border-radius: 35px;
					text-align: center;
					top: 6px;
					line-height: 26px;
					-webkit-transition: all 1s;
					-ms-transition: all 1s;
					transition: all 1s;
				}
				@include custommq ($max:1200px){
					font-size: 15px;
				}
				@include respond('phone'){
					margin:5px 0;
				}
			}
			@include respond('phone'){
				padding:15px 0;
			}
		}
		.pricingtable-title{
			position:absolute;
			top:0px;
			left:40px;
			font-size: 18px;
			line-height: 1.2;
			color:#fff;
			padding: 5px 25px;
			background: #92ADC6;
			&.premium {
				background-color: $yellow;
				color: #000;
			} 
			@include custommq ($max:1200px){
				font-size: 16px;
			}
		}
		@include custommq ($max:1200px){
			padding: 55px 30px 45px;
		}
		
		// Active
		&:hover,
		&.active{
			background:var(--primary);
			.btn-primary {
				background:#000;
				color:#fff;
			}
			.pricingtable-type,
			.pricingtable-price,
			.pricingtable-bx {
				color: #000 !important; 	
				p {
					color: var(--theme-text-color);
				}		
			}
			.pricingtable-title{
				background: #000;
				&.premium {
					color: white;
				}
			}
			.pricingtable-features{
				border-color: rgba(255,255,255,0.3);
				li {
					color: var(--theme-text-color);
					&:after {
						background: #000;
						color: var(--primary);
					}
				}
			}
			&:before {
			    opacity: 1;
			}
		}
	}
}
.membership-box {
	&:after {
		content: '';
		z-index: -1;
		top: 0;
		right: 0;
	    width: 59%;
		position: absolute;
		height: calc( 100% - 150px );
	    background-size: cover;
	    background-position: center;
	    background-repeat: no-repeat;
		background-image: url(../images/background/bg6.png);
		
	    @include respond('laptop'){
	    	width: 62%;
	    }
	    @include custommq ($max:1199px){
	    	width: 63%;
	    }
	    @include respond('tab-port'){
	    	display: none;
	    }
	}
	.pricing-content {
		padding-left: 70px;
		padding-right: 15px;
		@include custommq ($max:1199px){
	    	padding-left: 40px;
	    	padding-right: 0px;
	    }
	    @include respond('tab-port'){
	    	padding: 0;
	    }
	}
	.tab-content {
		.content {
			font-size: 18px;
			@include respond('phone-land'){
		    	font-size: 15px;
		    }
		}
	}
}
