.counter-wrapper1{
	position: relative;
    margin-bottom: -60px;
    z-index: 9;
	
	@include respond('tab-port'){
		margin-bottom: -100px;
		padding-top: 20px;
	}

	.counter-inner{
		padding: 80px 60px 50px;
		position:relative;
		z-index:1;
		margin: 0 -75px;
		transform: skew(0deg ,-8deg);
		
		.counter-box {
			display: block;
			text-align: center;
			align-items: center;
			justify-content: center;
			.title {
				margin:0;
				color:#FFBD13;
				font-size:80px;
				font-weight: 700;
			}
			p {
				margin:0;
				color:white;
				font-size: 24px;

				@include respond('phone-land'){
					font-size: 13px;
				}
			}
			@include respond('phone'){
				display:block;
				text-align:center;
			}
		}
		.triangle1 {
			position: absolute;
			top: 0;
			left: 2px;
			transform: translateY(-72%) rotate(8.5deg);
		}
		.triangle2 {
			position: absolute;
			bottom: 0;
			right: 2px;
			transform: translateY(72%) rotate(8.5deg);
		}
		@include respond('laptop'){
			margin: 0;
		}
		@include respond('tab-land'){
			padding: 50px 40px 20px;
			.counter-box{
				.title {
					font-size: 60px;
				}
				p {
					font-size: 18px;
				}
			}
		}
		@include respond('tab-port'){
			.counter-box{
				.title {
					font-size: 48px;
				}
				p {
					font-size: 16px;
				}
			}
		}
		@include respond('phone-land'){
			padding: 40px 30px 10px;
			
			.triangle1{
				top: 10px;
				left: 1px;
				width: 120px;
			}
			.triangle2 {
				bottom: 9px;
				right: 1px;
				width: 120px;
			}
		}
		@include respond('phone'){
			padding: 30px 30px 0px;
			.counter-box{
				.title {
					font-size: 42px;
				}
				p {
					font-size: 15px;
				}
			}
		}
	}
	.man{
		position: absolute;
		right: 0;
		top: -280px;
		width: 26vw;
		z-index: 9;
		@include respond('wide-desktop'){
			top: -180px;
			width: 20vw;
		}
		@include respond('tab-land'){
			top: -125px;
			width: 15vw;
		}
		@include respond('tab-port'){
			display: none;
		}
	}
}

// Clients Logo
.clients-logo{
    height: 50px;
	overflow:hidden;
	position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
	
	img{
		width: 135px;
	}
	&:hover{
		img{
			-webkit-animation: logoUpToDown 0.4s forwards;
			-moz-animation: logoUpToDown 0.4s forwards;
			animation: logoUpToDown 0.4s forwards;
		}
	}
	@include respond ('phone'){
		margin: 0 25px;
	}
}
@keyframes logoUpToDown{
	49% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	50%{
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	51%{
		opacity: 1;
	}
}

.dropdown-item{
	&.active,
	&:active {
		background-color: var(--primary);
	}
}

// About Wrapper2
.about-wrapper2{
	padding-top: 220px;
	@include respond('phone'){
		padding-top: 170px;
	}
}


.dzFormMsg ,
.dzSubscribeMsg {
	position: fixed;
    right: 6%;
    z-index: 999;
    bottom: 17px;
    .alert {
	    padding: 10px 15px;
	    background: transparent;
	    border: 0;
	    margin: 0;
	    position: relative;
	    &:after {
	    	content: '';
		    position: absolute;
		    z-index: -1;
		    left: 0;
		    top: 0;
		    height: 100%;
		    width: 100%;
		    transform: skewX(-12deg);
		    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	    }
	    &.alert-danger {
	    	&:after {
		    	background-color: #ffcccd;
	    	}
	    }
	    &.alert-success {
	    	&:after {
	    	    background-color: #d0f3db;
	    	}
	    }

    }
    @include custommq ($max:1199px){
    	right: 9%;
    	bottom: 17px;
    }
    @include respond ('phone-land'){
		right: 5%;
    	bottom: 18%;
		width: 90%;
	} 
    @include respond ('phone'){
    	bottom: 15%;
	} 
}


.calculate-table {
	padding-left:30px;
	table {
		margin-bottom: 30px;
		width: 100%;
		
		th,
		td{
		    text-align: left;
		    padding: 15px 20px;
		    border-right:  1px solid lightgray;
		    width: 38%;
		    &:last-child {
		    	border:none;
		    	width: 100%;
		    }
		    @include respond('phone-land'){
		        width: 50%;
		    }
		    @include respond('phone'){
		        padding: 10px 15px;
		    }
		}
		tbody tr {
			border-bottom: 1px solid lightgray;
			&:last-child {
		    	border:none;
		    }
		}
		thead {
			tr{
				border-bottom: 1px solid lightgray;
			} 
			th {
				color: #000;

			}
		}
	}
	.weight-info b{
		color: #000;
	}
	@include respond('tab-port'){
        padding-left:0px;
    }
}
 

.fitness-classes{
	.dz-media{
		width: 100%;
		height: 100%;
		
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include transitionMedium;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
	}
	.dz-info{
	    height: 100%;
		padding: 50px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include respond('laptop'){
	        padding: 30px;
	    }
	    .subtitle{
	    	font-size: 14px;
	    }
		.title{
			margin-bottom: 10px;
		}
		p{
			margin-bottom: 25px;
		}
	}
}

.z-index-none {
	z-index: unset;
}

@keyframes animateBGText{
	0%{
		transform: translateY(0);
	}
	50%{
		transform: translateY(12px);
		opacity: 0.4;
	}
}


.map {
	margin-top: -50px;
    position: relative;
    z-index: 1;
	
	@include custommq ($max:1199px){
		margin-top: -20px;
	}
    @include respond('tab-port'){ 
		margin: 0;
	}
}


/* Google Recaptcha */
.g-recaptcha,
#rc-imageselect {
    transform:scale(0.9);
    -webkit-transform:scale(0.9);
    -moz-transform:scale(0.9);
    -o-transform:scale(0.9);
    
    transform-origin:0 0;
    -o-transform-origin:0 0;
    -moz-transform-origin:0 0;
    -webkit-transform-origin:0 0;
}
@include respond('tab-land'){
	#rc-imageselect, 
	.g-recaptcha {
        transform:scale(0.7);
        -moz-transform:scale(0.7);
        -o-transform:scale(0.7);
        -webkit-transform:scale(0.7);
        
        transform-origin:0 0;
        -moz-transform-origin:0 0;
        -o-transform-origin:0 0;
        -webkit-transform-origin:0 0;
    
    }
}
@include respond('phone'){
	#rc-imageselect, 
	.g-recaptcha {
        transform:scale(0.7);
        -moz-transform:scale(0.7);
        -o-transform:scale(0.7);
        -webkit-transform:scale(0.7);
        
        transform-origin:0 0;
        -moz-transform-origin:0 0;
        -o-transform-origin:0 0;
        -webkit-transform-origin:0 0;
    
    }
}

.select-dropdown {
	&.style-1 {
		.select-dropdown-toggle {
			width: 100%;
			text-align: left;
			border-radius: 0;
			//height: 48px;
			padding: 0;
			border: none;
			border-bottom: 2px solid #D9D9D9;
			line-height: 48px;
			background-color: transparent;
			color: #666666;
			font-weight: 400;
			display: flex;
    		align-items: center;
			cursor: pointer;
			&:after {
				content: none;
			}
			i {
				font-size: 10px;
				margin-left: auto;
			}
		}
		.dropdown-menu.show {
			border: none;
			background-color: white;
			box-shadow: 0px 14px 30px 0px rgba(225, 50, 0,0.1);

			.dropdown-item {
				&:hover {
					background-color: var(--primary);
					color: white;
				}
				&:first-child {
					background-color: var(--primary);
					color: white;
				}	
			}
		}
	}
}