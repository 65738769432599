.about-bx1{
	&:after {
		content: '';
	    position: absolute;
	    height: calc(100% + 100px);
	    width: 100%;
	    top: 0px;
	    left: 0;
	    background-image: url(../images/about/about-bg.png);
	    background-position: center;
	    background-size: cover;
	    z-index: -2;
	}
	.section-head{
		p{
			color: $body-color;
		}
	}
	.dz-media {
		margin-right: 25px;
		overflow: visible;
		text-align: center;
		margin-bottom: 100px;
		
		ul {
			li {
				position: absolute;
			}
			li:nth-child(1) {
				bottom: 12%;
			    right: 0%;
			    background: var(--gradient-2);
			    padding: 20px 25px;
			    box-shadow: 0px 20px 50px 0px rgba(255, 63, 19, 0.3);
			    transform: skewY(-18deg);
    			span {
				    font-size: 110px;
				    color: var(--theme-text-color);
				    font-weight: 600;
				    line-height: 1;
				    display: inline-block;
    			}
			}
			li:nth-child(2) {
				bottom: 13%;
			    right: 7%;
			    background: var(--secondary);
			    padding: 10px 25px;
			    box-shadow: 20px 20px 40px 0px rgba(34, 34, 34, 0.4);
			    transform: skewY(-18deg);
    			span {
				    font-size: 35px;
				    color: #fff;
				    letter-spacing: 3px;
				    font-weight: 600;
				    line-height: 1;
				    display: inline-block;
    			}
			}
			li:nth-child(3) {
				bottom: -4%;
			    right: 0%;
				background: var(--gradient-1);
				height: 90px;
				width: 380px;
				transform: skewY(-18deg);
			}
		}
		img {
			width: 60%;

		    margin: auto;

		    text-align: center;

		    display: inline-block;
		}
		svg {
			position: absolute;
		    right: 0;
		    z-index: -1;
		    height: 100%;
		    width: calc(100% + 50px);
		}
		
	}
	@include respond('tab-land'){
		.dz-media{
			svg{
				width: 100%;
			}
			ul li{
				&:nth-child(1) span {
					font-size: 80px;
				}
				&:nth-child(2) {
					padding: 8px 20px;
					
					span {
						font-size: 20px;
					}
				}
				&:nth-child(3) {
					height: 70px;
					width: 300px;
				}
			}
		}
	}
	@include respond('tab-port'){
		.dz-media {
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 75px;
		}
	}
	@include respond('phone'){
		.dz-media ul li{
			&:nth-child(1){
				bottom: 15%;
				span {
					font-size: 60px;
				}
			}
			&:nth-child(2){
				span {
					font-size: 16px;
				}
			}
			&:nth-child(3) {
				height: 55px;
				width: 250px;
				bottom: -6%;
			}
		}
	}
}
