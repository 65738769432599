[data-theme-color="color_1"]{
    /* Primary */
	--primary: #FF8139;
	--primary-hover: #ff6106;
	--primary-dark: #ed5600;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(255,129,57,0.1);
	--rgba-primary-2: rgba(255,129,57,0.2);
	--rgba-primary-3: rgba(255,129,57,0.3);
	--rgba-primary-4: rgba(255,129,57,0.4);
	--rgba-primary-5: rgba(255,129,57,0.5);
	--rgba-primary-05: rgba(255,129,57,0.05);
	--rgba-primary-6: rgba(255,129,57,0.6);
	--rgba-primary-7: rgba(255,129,57,0.7);
	--rgba-primary-8: rgba(255,129,57,0.8);
	--rgba-primary-9: rgba(255,129,57,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #222222;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #FF3C11 1.12%, #FF8139 44%);
	--gradient-1: linear-gradient(90deg, #FF3F13 47.55%, #FF7F38 100.15%);
	--gradient-2: linear-gradient(90deg, #FF8039 0%, #FF3F13 100%);	
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	/* --theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern1.svg); */
	
	--theme3-bg-color: #201916;
	--theme3-secondary: #301f18;
	--theme3-secondary2: #ffdd39;
}

[data-theme-color="color_2"]{
    /* Primary */
	--primary: #FCDE3B;
	--primary-hover: #e9ce3c;
	--primary-dark: #ebc600;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(252,222,59,0.1);
	--rgba-primary-2: rgba(252,222,59,0.2);
	--rgba-primary-3: rgba(252,222,59,0.3);
	--rgba-primary-4: rgba(252,222,59,0.4);
	--rgba-primary-5: rgba(252,222,59,0.5);
	--rgba-primary-05: rgba(252,222,59,0.05);
	--rgba-primary-6: rgba(252,222,59,0.6);
	--rgba-primary-7: rgba(252,222,59,0.7);
	--rgba-primary-8: rgba(252,222,59,0.8);
	--rgba-primary-9: rgba(252,222,59,0.9);
 
	/* Other */
	--secondary: #232323;
	--theme-text-color: #232323;
	--title: #000000;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #f5d002 1.12%, #FCDE3B 44%);
	--gradient-1: linear-gradient(90deg, #ebc600 47.55%, #fcde3b 100.15%);
	--gradient-2: linear-gradient(90deg, #ebc600 0%, #fcde3b 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	//--theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern2.svg);
	
	--theme3-bg-color: #1e1c11;
	--theme3-secondary: #34311d;
	--theme3-secondary2: #ff9780;
}

[data-theme-color="color_3"]{
    /* Primary */
	--primary: #06C6D1;
	--primary-hover: #05b5bf;
	--primary-dark: #009ea7;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(6,198,209,0.1);
	--rgba-primary-2: rgba(6,198,209,0.2);
	--rgba-primary-3: rgba(6,198,209,0.3);
	--rgba-primary-4: rgba(6,198,209,0.4);
	--rgba-primary-5: rgba(6,198,209,0.5);
	--rgba-primary-05: rgba(6,198,209,0.05);
	--rgba-primary-6: rgba(6,198,209,0.6);
	--rgba-primary-7: rgba(6,198,209,0.7);
	--rgba-primary-8: rgba(6,198,209,0.8);
	--rgba-primary-9: rgba(6,198,209,0.9);
	
	/* Secondary RGBA */
	--rgba-secondary-1: rgba(54,42,171,0.1);
	--rgba-secondary-2: rgba(54,42,171,0.2);
	--rgba-secondary-3: rgba(54,42,171,0.3);
	--rgba-secondary-4: rgba(54,42,171,0.4);
	--rgba-secondary-5: rgba(54,42,171,0.5);
	--rgba-secondary-6: rgba(54,42,171,0.6);
	--rgba-secondary-7: rgba(54,42,171,0.7);
	--rgba-secondary-8: rgba(54,42,171,0.8);
	--rgba-secondary-9: rgba(54,42,171,0.9);
	
	/* Other */
	--purple: #9A69F9;
	--blue: #281E8B;
	--dark: #281E8B;
	--title: #000000;
	--secondary: #232323;
	--theme-text-color: #fff;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #13b0b9 1.12%, #06c6d1 44%);
	--gradient-1: linear-gradient(90deg, #00b1bb 47.55%, #06c6d1 100.15%);
	--gradient-2: linear-gradient(90deg, #00b1bb 0%, #06c6d1 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	--theme3-bg-color: #281E8B;
	--theme3-secondary: #362AAB;
	--theme3-secondary2: #9A69F9;
}

[data-theme-color="color_4"]{
    /* Primary */
	--primary: #b8c724;
	--primary-hover: #a4b316;
	--primary-dark: #a7b70e;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(184,199,36,0.1);
	--rgba-primary-2: rgba(184,199,36,0.2);
	--rgba-primary-3: rgba(184,199,36,0.3);
	--rgba-primary-4: rgba(184,199,36,0.4);
	--rgba-primary-5: rgba(184,199,36,0.5);
	--rgba-primary-05: rgba(184,199,36,0.05);
	--rgba-primary-6: rgba(184,199,36,0.6);
	--rgba-primary-7: rgba(184,199,36,0.7);
	--rgba-primary-8: rgba(184,199,36,0.8);
	--rgba-primary-9: rgba(184,199,36,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #000000;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #a8b900 1.12%, #b8c724 44%);
	--gradient-1: linear-gradient(90deg, #b4c509 47.55%, #b8c724 100.15%);
	--gradient-2: linear-gradient(90deg, #b8c724 0%, #abbb14 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	//--theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern4.svg);
	
	--theme3-bg-color: #171809;
	--theme3-secondary: #0f1006;
	--theme3-secondary2: #df7056;
}

[data-theme-color="color_5"]{
    /* Primary */
	--primary: #f7244f;
	--primary-hover: #f50637;
	--primary-dark: #dd002d;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(247,36,79,0.1);
	--rgba-primary-2: rgba(247,36,79,0.2);
	--rgba-primary-3: rgba(247,36,79,0.3);
	--rgba-primary-4: rgba(247,36,79,0.4);
	--rgba-primary-5: rgba(247,36,79,0.5);
	--rgba-primary-05: rgba(247,36,79,0.05);
	--rgba-primary-6: rgba(247,36,79,0.6);
	--rgba-primary-7: rgba(247,36,79,0.7);
	--rgba-primary-8: rgba(247,36,79,0.8);
	--rgba-primary-9: rgba(247,36,79,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #000000;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #ff0034 1.12%, #f7244f 44%);
	--gradient-1: linear-gradient(90deg, #ff0034 47.55%, #f7244f 100.15%);
	--gradient-2: linear-gradient(90deg, #f7244f 0%, #eb0030 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	//--theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern5.svg);
	
	--theme3-bg-color: #420b17;
	--theme3-secondary: #160105;
	--theme3-secondary2: #f7c024;
}
[data-theme-color="color_6"]{
    /* Primary */
	--primary: #b65fbd;
	--primary-hover: #b249bb;
	--primary-dark: #ab4ab3;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(182,95,189,0.1);
	--rgba-primary-2: rgba(182,95,189,0.2);
	--rgba-primary-3: rgba(182,95,189,0.3);
	--rgba-primary-4: rgba(182,95,189,0.4);
	--rgba-primary-5: rgba(182,95,189,0.5);
	--rgba-primary-05: rgba(182,95,189,0.05);
	--rgba-primary-6: rgba(182,95,189,0.6);
	--rgba-primary-7: rgba(182,95,189,0.7);
	--rgba-primary-8: rgba(182,95,189,0.8);
	--rgba-primary-9: rgba(182,95,189,0.9);
	
	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #000000;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #b751bf 1.12%, #b65fbd 44%);
	--gradient-1: linear-gradient(90deg, #98489f 47.55%, #b65fbd 100.15%);
	--gradient-2: linear-gradient(90deg, #b65fbd 0%, #a44bab 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	//--theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern6.svg);
	
	--theme3-bg-color: #350838;
	--theme3-secondary: #19031a;
	--theme3-secondary2: #5fbd92;
}
[data-theme-color="color_7"]{
    /* Primary */
	--primary: #977fff;
	--primary-hover: #8f76fd;
	--primary-dark: #826aed;
	
	/* Primary RGBA */
	--rgba-primary-1: rgba(151,127,255,0.1);
	--rgba-primary-2: rgba(151,127,255,0.2);
	--rgba-primary-3: rgba(151,127,255,0.3);
	--rgba-primary-4: rgba(151,127,255,0.4);
	--rgba-primary-5: rgba(151,127,255,0.5);
	--rgba-primary-05: rgba(151,127,255,0.05);
	--rgba-primary-6: rgba(151,127,255,0.6);
	--rgba-primary-7: rgba(151,127,255,0.7);
	--rgba-primary-8: rgba(151,127,255,0.8);
	--rgba-primary-9: rgba(151,127,255,0.9);

	/* Other */
	--secondary: #232323;
	--theme-text-color: #fff;
	--title: #232323;
	
	/* Gradient */
	--gradient: linear-gradient(89deg, #7961e5 1.12%, #977fff 44%);
	--gradient-1: linear-gradient(90deg, #806bd9 47.55%, #977fff 100.15%);
	--gradient-2: linear-gradient(90deg, #977fff 0%, #745ed7 100%);
	
	/* Box Shadow */
	--primary-shadow: linear-gradient(90deg, #fcde3b 47.55%, #ebc600 100.15%);
	
	/* extra variable */
	//--theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern7.svg);
	
	--theme3-bg-color: #241954;
	--theme3-secondary: #0f0636;
	--theme3-secondary2: #7ff3ff;
}